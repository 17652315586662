import React, { Component } from 'react'
import { Fade } from 'react-awesome-reveal'

class About extends Component {
	render() {
		let jhonData = this.props.jhonData
		var { aClass } = this.props

		// Convertir le texte en tableau de phrases
		let aboutDetailsArray = jhonData.aboutdetails.split('.').filter(Boolean)

		return (
			<section className={`${aClass}`} id='about'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-7 d-flex align-items-center'>
							<Fade direction='up' triggerOnce='true' damping={0.1}>
								<div className='about_content'>
									<h2 className='t_color'>{jhonData.aboutme}</h2>
									<h6>{jhonData.role}</h6>
									{/* Afficher chaque phrase dans un élément <p> distinct */}
									{aboutDetailsArray.map((sentence, index) => (
										<p key={index}>{sentence.trim()}.</p>
									))}
								</div>
							</Fade>
						</div>
						<div className='col-lg-5'>
							<div className='about_img'>
								<Fade direction='right' duration={1500}>
									<img src={require('../image/apporoach_man_img.png')} alt='' />
								</Fade>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default About
