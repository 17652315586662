import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
/*-----pages-----*/
import Home from './Home'
// import Home2 from './Home2'
// import Home3 from './Home3'
// import Home4 from './Home4'
// import Home5 from './Home5'
// import Home6 from './Home6'
// import Homefrelencer from './Home-frelencer'
// import HomeDeveloper from './Home-developer'
import NotFound from './404'
import Login from './Login'

function App() {
	return (
		<React.Fragment>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/Login' element={<Login />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</React.Fragment>
	)
}

export default App

