import React, { Component } from 'react'
import { Parallax } from 'react-parallax/'
import { Fade } from 'react-awesome-reveal'
const image = require('../../image/profile.jpg')

class Banner extends Component {
	render() {
		let jhonData = this.props.jhonData
		return (
			<section id='home'>
				<Parallax bgImage={image} strength={500} className='banner_area'>
					<div className='container'>
						<div className='banner_content'>
							<Fade direction='up'>
								<h5>I am</h5>
							</Fade>
							<Fade direction='up' duration={1500}>
								<h2>{jhonData.name}</h2>
							</Fade>
							<Fade direction='up' duration={1500}>
								<h4 className='wow fadeInUp anoimated'>{jhonData.role}</h4>
							</Fade>
							<Fade direction='up' cascade duration={1000} damping={0.1}>
								<ul className='list_style social_icon'>
									<li>
										<a href={jhonData.socialLinks[0].url} target='_blank' rel='noreferrer'>
											<i className={jhonData.socialLinks[0].className}></i>
										</a>
									</li>
									<li>
										<a href='https://github.com/KANTANDEV/KANTANDEV' target='_blank' rel='noreferrer'>
											<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='30' height='30' viewBox='0 0 64 64'>
												<linearGradient id='KpzH_ttTMIjq8dhx1zD2pa_52539_gr1' x1='30.999' x2='30.999' y1='16' y2='55.342' gradientUnits='userSpaceOnUse' spreadMethod='reflect'>
													<stop offset='0' stop-color='#6dc7ff'></stop>
													<stop offset='1' stop-color='#e6abff'></stop>
												</linearGradient>
												<path
													fill='url(#KpzH_ttTMIjq8dhx1zD2pa_52539_gr1)'
													d='M25.008,56.007c-0.003-0.368-0.006-1.962-0.009-3.454l-0.003-1.55 c-6.729,0.915-8.358-3.78-8.376-3.83c-0.934-2.368-2.211-3.045-2.266-3.073l-0.124-0.072c-0.463-0.316-1.691-1.157-1.342-2.263 c0.315-0.997,1.536-1.1,2.091-1.082c3.074,0.215,4.63,2.978,4.694,3.095c1.569,2.689,3.964,2.411,5.509,1.844 c0.144-0.688,0.367-1.32,0.659-1.878C20.885,42.865,15.27,40.229,15.27,30.64c0-2.633,0.82-4.96,2.441-6.929 c-0.362-1.206-0.774-3.666,0.446-6.765l0.174-0.442l0.452-0.144c0.416-0.137,2.688-0.624,7.359,2.433 c1.928-0.494,3.969-0.749,6.074-0.759c2.115,0.01,4.158,0.265,6.09,0.759c4.667-3.058,6.934-2.565,7.351-2.433l0.451,0.145 l0.174,0.44c1.225,3.098,0.813,5.559,0.451,6.766c1.618,1.963,2.438,4.291,2.438,6.929c0,9.591-5.621,12.219-10.588,13.087 c0.563,1.065,0.868,2.402,0.868,3.878c0,1.683-0.007,7.204-0.015,8.402l-2-0.014c0.008-1.196,0.015-6.708,0.015-8.389 c0-2.442-0.943-3.522-1.35-3.874l-1.73-1.497l2.274-0.253c5.205-0.578,10.525-2.379,10.525-11.341c0-2.33-0.777-4.361-2.31-6.036 l-0.43-0.469l0.242-0.587c0.166-0.401,0.894-2.442-0.043-5.291c-0.758,0.045-2.568,0.402-5.584,2.447l-0.384,0.259l-0.445-0.123 c-1.863-0.518-3.938-0.796-6.001-0.806c-2.052,0.01-4.124,0.288-5.984,0.806l-0.445,0.123l-0.383-0.259 c-3.019-2.044-4.833-2.404-5.594-2.449c-0.935,2.851-0.206,4.892-0.04,5.293l0.242,0.587l-0.429,0.469 c-1.536,1.681-2.314,3.712-2.314,6.036c0,8.958,5.31,10.77,10.504,11.361l2.252,0.256l-1.708,1.49 c-0.372,0.325-1.03,1.112-1.254,2.727l-0.075,0.549l-0.506,0.227c-1.321,0.592-5.839,2.162-8.548-2.485 c-0.015-0.025-0.544-0.945-1.502-1.557c0.646,0.639,1.433,1.673,2.068,3.287c0.066,0.19,1.357,3.622,7.28,2.339l1.206-0.262 l0.012,3.978c0.003,1.487,0.006,3.076,0.009,3.444L25.008,56.007z'
												></path>
												<linearGradient id='KpzH_ttTMIjq8dhx1zD2pb_52539_gr2' x1='32' x2='32' y1='5' y2='59.167' gradientUnits='userSpaceOnUse' spreadMethod='reflect'>
													<stop offset='0' stop-color='#1a6dff'></stop>
													<stop offset='1' stop-color='#c822ff'></stop>
												</linearGradient>
												<path fill='url(#KpzH_ttTMIjq8dhx1zD2pb_52539_gr2)' d='M32,58C17.663,58,6,46.337,6,32S17.663,6,32,6s26,11.663,26,26S46.337,58,32,58z M32,8 C18.767,8,8,18.767,8,32s10.767,24,24,24s24-10.767,24-24S45.233,8,32,8z'></path>
											</svg>
										</a>
									</li>
									<li>
										<a href='https://www.kaggle.com/kantanyagami' target='_blank' rel='noreferrer'>
											<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='30px' id='kaggle'>
												<path fill='#35B5E2' d='M7.998,0H5.598C5.365,0,5.25,0.118,5.25,0.352v23.295c0,0.235,0.115,0.352,0.348,0.352h2.401c0.232,0,0.348-0.117,0.348-0.352v-5.11l1.427-1.374l5.115,6.59C15.027,23.918,15.189,24,15.374,24h3.097c0.161,0,0.254-0.047,0.278-0.141l-0.07-0.353l-6.749-8.494l6.471-6.343c0.122-0.124,0.078-0.493-0.244-0.493h-3.202c-0.161,0-0.324,0.083-0.487,0.247l-6.123,6.273V0.352C8.347,0.118,8.231,0,7.998,0L7.998,0z'></path>
												<path fill='#2E9EC5' d='M7.998,0H5.598C5.365,0,5.25,0.118,5.25,0.352v23.295c0,0.235,0.115,0.352,0.348,0.352h2.401c0.232,0,0.348-0.117,0.348-0.352v-5.11l1.425-1.372v-3.929l-1.425,1.46V0.352C8.347,0.118,8.231,0,7.998,0L7.998,0z'></path>
											</svg>
										</a>
									</li>
								</ul>
							</Fade>
						</div>
					</div>
				</Parallax>
			</section>
		)
	}
}

export default Banner
