import React from 'react'
import logo from './image/logo.png'
import axios from 'axios'

const Login = () => {
	const handleSubmit = async (e) => {
		e.preventDefault()
		const email = document.getElementById('email').value
		const password = document.getElementById('password').value

		const regex = /^kantanyagamidev@gmail\.com$/
		const errorElement = document.querySelector('.error')

		if (!regex.test(email)) {
			errorElement.innerHTML = 'Email invalide'
			errorElement.style.color = 'red'
		} else {
			try {
				const response = await axios.post('http://localhost:5000/api/auth/login', {
					email: email,
					password: password,
				})
				console.log(response.cookie)
				errorElement.innerHTML = response.data.message
				errorElement.style.color = 'green'
				// setTimeout(() => {
				// 	window.location = '/'
				// }, 1000)
			} catch (error) {
				const serverError = error.response.data.error
				errorElement.innerHTML = serverError
				errorElement.style.color = 'red'
			}
		}
	}

	return (
		<div className='logincontainer'>
			<div className='card-container'>
				<div className='card-bg'></div>
				<div className='card'>
					<a href='/'>
						<img src={logo} alt='Logo' className='logo' />
					</a>
					<div className='form-container'>
						<h1>Connecter - vous ! </h1>
						<div className='form-group'>
							<input type='text' id='email' name='email' className='input-field' placeholder='Email' />
						</div>
						<div className='form-group'>
							<input type='password' id='password' name='password' className='input-field' placeholder='Mot de passe' />
						</div>
						<button onClick={handleSubmit} className='submit-btn'>
							Connexion
						</button>
						<p className='error'></p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
