import React, { Component } from 'react'
import { Fade } from 'react-awesome-reveal'
import Form from './Form'

class Contact extends Component {
	render() {
		let jhonData = this.props.jhonData
		return (
			<section className='contact-area' id='contact'>
				<div className='container'>
					<div className='row row-reverse'>
						{jhonData.contact &&
							jhonData.contact.map((item) => {
								return (
									<React.Fragment key={item.id}>
										<div className='col-md-5'>
											<Fade direction='left' triggerOnce duration={500}>
												<div className='contact_info'>
													<h4>{item.title}</h4>
													<ul className='nav'>
														{item.menuItems.map((info) => {
															return (
																<li className='item' key={info.id}>
																	<div className='media'>
																		<i className={info.icon}></i>
																		<div className='media-body'>
																			<a href={info.link}>{info.text}</a>
																		</div>
																	</div>
																</li>
															)
														})}
													</ul>
												</div>
											</Fade>
										</div>
									</React.Fragment>
								)
							})}
						<div className='col-md-7'>
							<Fade direction='Right' triggerOnce duration={800}>
								<div className='input_form'>
									{/* <h4>{jhonData.contacttitle}</h4> */}
									{/* <Form />
									<div id='success'>Votre message a bien été envoyé !</div>
									<div id='error'>Oups ! Il y a quelque chose qui ne va pas. Veuillez réessayer</div> */}
								</div>
							</Fade>
						</div>
					</div>
				</div>
			</section>
		)
	}
}
export default Contact
