const jhonData = {
	name: 'Kantan Yagami',
	name2: 'Kantan Yagami',
	name3: 'Kantan Yagami',
	role: 'Développeur full stack & Ingénieur IA',
	ptext: 'Creative Designer & Developer located in London.',
	socialLinks: [
		{
			name: 'Facebook',
			url: 'https://www.linkedin.com/in/kantan-yagami-534919232/',
			className: 'social_linkedin',
		},
		{
			name: 'twitter',
			url: 'http://www.twitter.com/',
			className: 'social_twitter',
		},
		{
			name: 'googleplus',
			url: 'http://www.googleplus.com',
			className: 'social_googleplus',
		},
		{
			name: 'pinterest',
			url: 'http://www.pinterest.com',
			className: 'social_pinterest',
		},
		{
			name: 'tumblr',
			url: 'http://www.tumblr.com',
			className: 'social_tumblr',
		},
	],
	aboutme: 'À propos',
	aboutdetails:
		"Ma première incursion dans le vaste univers de l'informatique a suscité en moi une fascination pour sa complexité intrinsèque et sa diversité infinie.Issue d'un parcours initial en électricité, j'ai acquis une expertise considérable dans le domaine du matériel informatique.Cependant, c'est lors d'une mission professionnelle dans le domaine de la sécurité électronique que j'ai pris conscience de l'immense potentiel inhérent à la programmation logicielle.Cette prise de conscience a été déterminante, m'incitant à orienter mon parcours académique vers le développement informatique, avec une spécialisation particulière en cybersécurité.À l'heure actuelle, j'ai consolidé une solide compétence en tant que développeur full-stack et pentester.Non content de me reposer sur mes acquis, je m'investis actuellement dans plusieurs parcours d'apprentissage visant à approfondir mes connaissances. J'entreprends une spécialisation en apprentissage automatique à l'Université de Washington ainsi qu'a Stanford et en mathématiques pour l'apprentissage automatique à l'Imperial College de Londres.En parallèle, je suis en Master d'ingenierie en intelligence artificielle sur Openclassrooms.",
	service: [
		{
			id: 1,
			iconName: 'icon-desktop',
			serviceTitle: 'Web Design',
			sDetails: 'Création de maquettes et de prototypes pour des applications web et mobiles.',
		},
		{
			id: 2,
			iconName: 'icon-pencil',
			serviceTitle: 'Développement informatique',
			sDetails: 'Création de sites web, app web, logiciels SaaS, scripts et applications de bureau.',
		},
		{
			id: 3,
			iconName: 'icon-presentation',
			serviceTitle: 'Enseignement',
			sDetails: 'Enseignant vacataire pour les étudiants du supérieur en développement et cybersécurité',
		},
		{
			id: 4,
			iconName: 'icon-linegraph',
			serviceTitle: 'IA & Data',
			sDetails: 'Création de datasets et mise en place de modèles de machine learning.',
		},
		{
			id: 5,
			iconName: 'icon-gears',
			serviceTitle: 'Recherches et écrits',
			sDetails: "Rédaction d’articles sur les nouvelles technologies et l'intelligence artificielle.",
		},
		{
			id: 6,
			iconName: 'icon-tools',
			serviceTitle: 'Gestion de projets',
			sDetails: "Gestion de projets techniques et management d'équipes de développeurs.",
		},
	],
	contact: [
		{
			id: '1',
			title: 'Informations de contact',
			menuItems: [
				// {
				// 	id: 1,
				// 	icon: 'icon_pin_alt',
				// 	text: 'Address: Z105 - Tan Thinh, Thai Nguyen - Viet Nam',
				// },
				// {
				// 	id: 2,
				// 	icon: 'icon_phone',
				// 	text: 'Phone: +1.900.3456.789',
				// },
				{
					id: 3,
					icon: 'icon_mail',
					text: 'Email: kantanyagamidev@gmail.com',
					link: 'mailto:kantanyagamidev@gmail.com',
				},
				{
					id: 4,
					icon: 'icon_globe',
					text: 'Github: https://github.com/KANTANDEV',
					link: 'https://github.com/KANTANDEV',
				},
			],
		},
	],
	devloperBanner: [
		{
			id: 1,
			subtile: 'Front-End Developer',
			title: 'Talk is cheap. \n Show me the code',
			discription: 'I design and code beautifully simple things,\n and I love what I do.',
		},
	],
	devAbout: [
		{
			id: 1,
			smalltitle: 'Introduce',
			aHead: 'Passion \n Heart & Soul',
			aHeadTwo: 'Every great design begin with an even better story',
			adiscription: "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
		},
	],
	aboutSkill: [
		{
			id: 1,
			workName: 'Design',
			text: 'Create digital products with unique idea',
			complateProject: '20 Projects',
			icon: 'icon-tools',
		},
		{
			id: 2,
			workName: 'front-End',
			text: 'My coding structure is clean and smooth',
			complateProject: '165 Projects',
			icon: 'icon-genius',
		},
		{
			id: 3,
			workName: 'SEO',
			text: 'Boost your business with SEO optimize.',
			complateProject: '5 Projects',
			icon: 'icon-linegraph',
		},
	],
	awards: [
		{
			id: 1,
			image: 'logo1.png',
			ctile: 'Site of the year 2020',
			date: 'April 2020',
			description: 'For most favorited design voted by NY City Style customers',
			url: 'http://www.pinterest.com',
			linktext: 'view More',
		},
		{
			id: 2,
			image: 'logo2.png',
			ctile: 'Theme of the day 2021',
			date: 'March 2021',
			description: 'For most favorited design voted by NY City Style customers',
			url: 'http://www.pinterest.com',
			linktext: 'view More',
		},
		{
			id: 3,
			image: 'logo2.png',
			ctile: 'Best design awwards',
			date: 'March 2021',
			description: 'For most favorited design voted by NY City Style customers',
			url: 'http://www.pinterest.com',
			linktext: 'view More',
		},
	],
	contacttitle: 'Formulaire de contact',
}

export default jhonData
