import React, { Component } from 'react'
import Sectiontitle from '../component/Banner/Sectiontitle'

class Clientslogo extends Component {
	render() {
		return (
			<section className='clients_logo_area'>
				<div className='container'>
					<Sectiontitle Title='Hard Skills' TitleP='Découvrez mes compétences techniques essentielles, alliant expertise moderne et maîtrise traditionnelle, essentielles à la réussite de projets numériques complexes.' />
					<div className='clients_slider'>
						<div className='image-row'>
							{/* Première ligne d'images */}
							<img src={require('../image/9.png')} alt='Logo HTML' />
							<img src={require('../image/10.png')} alt='Logo CSS' />
							<img src={require('../image/1.png')} alt='Logo Javascript' />
							<img src={require('../image/2.png')} alt='Logo Typescript' />
							<img src={require('../image/3.png')} alt='Logo React' />
							<img src={require('../image/5.png')} alt='Logo Python' />
						</div>
						<div className='image-row'>
							{/* Deuxième ligne d'images */}
							<img src={require('../image/12.png')} alt='Logo Saas' />
							<img src={require('../image/11.png')} alt='Logo Tailwind' />
							<img src={require('../image/4.png')} alt='Logo Nodejs' />
							<img src={require('../image/6.png')} alt='Logo Numpy' />
							<img src={require('../image/7.png')} alt='Logo Mongo DB' />
							<img src={require('../image/8.png')} alt='Logo MySQL' />
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default Clientslogo
