import React, { Component } from 'react'
import Slider from 'react-slick'

class TestimonialSlider extends Component {
	render() {
		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			arrows: false,
			autoplay: true,
			pauseOnHover: true,
			autoplaySpeed: 6000,
			slidesToShow: 1,
			slidesToScroll: 1,
		}
		return (
			<div>
				<Slider {...settings} className='testimonial_slider'>
					<div className='item'>
						<div className='author_img'>
							<img src={require('../../image/OC.png')} alt='' />
						</div>
						<h6>Développement informatique</h6>
						<span>Openclassrooms</span>
						<p>Titres RNCP niveau 5 en développement web et de niveau 6 en développement d'application - JavaScript React</p>
					</div>
					<div className='item'>
						<div className='author_img'>
							<img src={require('../../image/CI.png')} alt='' />
						</div>
						<h6>Cybersécurité</h6>
						<span>Cyberini & Udemy</span>
						<p>Certifications test d'intrusion réseau et web, Certification développement d'outils de cybersécurité, Certification Hacking Ethique Sécurité / sûreté de l'information des systèmes informatiques</p>
					</div>
					<div className='item'>
						<div className='author_img'>
							<img src={require('../../image/WU.jpg')} alt='' />
						</div>
						<h6>intelligence artificielle et Data</h6>
						<span>OpenClassrooms / University of Washington / University of Stanford / Imperial College London / IBM</span>
						<p>Titre RNCP niveau 7 Ingénieur IA, Spécialisation Machine Learning, Spécialisation Mathématiques pour l'apprentissage automatique, IBM AI Engineering Certificat Professionnel</p>
					</div>
				</Slider>
			</div>
		)
	}
}
export default TestimonialSlider
