import React, { Component } from 'react'
import Isotope from 'isotope-layout/js/isotope'
import ImagesLoaded from 'imagesloaded/imagesloaded'

class ItemGrid extends Component {
	state = {
		activeItem: '*',
	}

	componentDidMount() {
		var imgLoad = new ImagesLoaded('.grid')

		imgLoad.on('progress', function (instance, image) {
			this.iso = new Isotope('.grid', {
				itemSelector: '.grid-item',
				layoutMode: 'masonry',
			})
		})
	}
	onFilterChange = (newFilter) => {
		this.setState({ activeItem: newFilter })
		if (this.iso === undefined) {
			this.iso = new Isotope('.grid', {
				itemSelector: '.grid-item',
				layoutMode: 'masonry',
			})
		}

		// this.iso.arrange({ filter: newFilter });

		if (newFilter === '*') {
			this.iso.arrange({ filter: `*` })
		} else {
			this.iso.arrange({ filter: `.${newFilter}` })
		}
	}

	onActive = (v) => (v === this.state.activeItem ? 'active' : '')
	render() {
		return (
			<div>
				<ul className='list_style portfolio_menu text-center'>
					<li
						className={`${this.onActive('*')}`}
						data-wow-delay='0.1s'
						data-filter='*'
						onClick={() => {
							this.onFilterChange('*')
						}}
					>
						Tout
					</li>
					<li
						className={`${this.onActive('web')}`}
						data-wow-delay='0.3s'
						data-filter='web'
						onClick={() => {
							this.onFilterChange('web')
						}}
					>
						Web
					</li>
					<li
						className={`${this.onActive(`ia`)}`}
						data-wow-delay='0.6s'
						data-filter='develop'
						onClick={() => {
							this.onFilterChange('ia')
						}}
					>
						IA
					</li>
					<li
						className={`${this.onActive(`publications`)}`}
						data-wow-delay='0.8s'
						data-filter='market'
						onClick={() => {
							this.onFilterChange('publications')
						}}
					>
						Publications
					</li>
				</ul>
				{/* //! Filter Web */}
				<div className='grid row'>
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/1.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Intégration d'une maquette en HTML CSS</h6>
									<a href='https://kantandev.github.io/Booki/' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Demo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Les Petits Plats */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/2.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Développez un algorithme de recherche en JavaScript</h6>
									<a href='https://kantandev.github.io/Les-Petits-Plats/' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Demo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? OhMyFood */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/3.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Dynamisez une page web avec des animations CSS</h6>
									<a href='https://kantandev.github.io/Ohmyfood/' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Demo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? La panthere  */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/4.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Optimisez un site web existant</h6>
									<a href='https://github.com/KANTANDEV/La-Panthere' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Kanap */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/5.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Construisez un site e-commerce en JavaScript</h6>
									<a href='https://github.com/KANTANDEV/Kanap' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Piiquante */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/6.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>API pour une application d'avis gastronomiques</h6>
									<a href='https://github.com/KANTANDEV/PIIQUANTE' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? GameOn */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/8.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Landing page avec Javascript</h6>
									<a href='https://kantandev.github.io/GameOn/' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Demo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Groupomania */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/7.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Réseau Social d'Entreprise</h6>
									<a href='https://github.com/KANTANDEV/Groupomania' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? FishEye */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/9.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Site accessible pour une plateforme de photographes</h6>
									<a href='https://kantandev.github.io/FishEye/' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Demo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Billed  */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/10.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Débuggez et testez un SaaS RH</h6>
									<a href='https://github.com/KANTANDEV/Billed' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Demo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Learn-Home  */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/11.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Cadrage App de soutien scolaire</h6>
									<a href='https://github.com/KANTANDEV/Learn-Home' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Kasa */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/12.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Application Web avec React et React Router</h6>
									<a href='https://github.com/KANTANDEV/Kasa' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? SportSee */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/13.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'> Tableau de bord d'analytics avec React</h6>
									<a href='https://github.com/KANTANDEV/SportSee' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Remede_Agency-Argent-Bank */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/14.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>API pour un compte utilisateur bancaire avec React</h6>
									<a href='https://github.com/KANTANDEV/Remede_Agency-Argent-Bank' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? HRnet */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item web'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/15.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Migration jQuery vers React</h6>
									<a href='https://github.com/KANTANDEV/HRnet' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>

					{/* //! Filter AI */}
					{/* //? Images_Classifier */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item ia'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/16.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Classificateur d'images KNN</h6>
									<a href='https://github.com/KANTANDEV/Images_Classifier' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Face-swap */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item ia'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/17.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Face-swap with OpenCV</h6>
									<a href='https://github.com/KANTANDEV/Face-swap' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Open-Food-Facts */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item ia'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/18.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Analyse exploratoire des donnée</h6>
									<a href='https://github.com/KANTANDEV/Open-Food-Facts' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //? Smart - city */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item ia'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/19.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Analyse pré-exploratoire de donnée</h6>
									<a href='https://github.com/KANTANDEV/Smart-City' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Repo
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* //! Filter Publications */}
					<div className='col-md-3 col-sm-6 col-xs-12 grid-item publications'>
						<div className='portfolio hover-style'>
							<img src={require('../../image/portfolio/20.png')} alt='' />
							<div className='item-img-overlay'>
								<div className='overlay-info text-center'>
									<h6 className='sm-titl'>Le web 3 l'avenir du monde numérique ?</h6>
									<a href='https://www.amazon.fr/WEB-LAVENIR-DU-MONDE-NUM%C3%89RIQUE/dp/B0BN62H45B/ref=sr_1_1?__mk_fr_FR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=le+web+3+l%27avenir+du+monde+numerique&qid=1670276277&sr=8-1' rel='noreferrer' target='_blank' className='rounded font-bold py-2 px-4 bg-cyan-300'>
										Acheter
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ItemGrid
